
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Router from "./pages/router.tsx";
import React from "react";
import Layout from "./pages/Layout.tsx";
import Home from "./pages/Home.tsx";
import NoPage from "./pages/NotFound.tsx";
import Card from "./pages/card.tsx";

const App = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Card />} />
          <Route path="Card" element={<Card />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
  </BrowserRouter>
  );
};

export default App;