import React from "react";
import WaterWave from "react-water-wave";
import image from "../images/just-chatting-NOCHAT.png";
import sip from "../images/Sip.gif";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Box, Container, createSvgIcon, CssBaseline, Grid, IconButton, SvgIcon, Typography } from "@mui/material";

const TwitchIcon = createSvgIcon(
  <SvgIcon>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M11.64 5.93h1.43v4.28h-1.43m3.93-4.28H17v4.28h-1.43M7 2L3.43 5.57v12.86h4.28V22l3.58-3.57h2.85L20.57 12V2m-1.43 9.29l-2.85 2.85h-2.86l-2.5 2.5v-2.5H7.71V3.43h11.43Z"
      />
    </svg>
  </SvgIcon>,
  "Twitch"
);

const TikTokIcon = createSvgIcon(
  <SvgIcon>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M16.6 5.82s.51.5 0 0A4.278 4.278 0 0 1 15.54 3h-3.09v12.4a2.592 2.592 0 0 1-2.59 2.5c-1.42 0-2.6-1.16-2.6-2.6c0-1.72 1.66-3.01 3.37-2.48V9.66c-3.45-.46-6.47 2.22-6.47 5.64c0 3.33 2.76 5.7 5.69 5.7c3.14 0 5.69-2.55 5.69-5.7V9.01a7.35 7.35 0 0 0 4.3 1.38V7.3s-1.88.09-3.24-1.48"
      ></path>
    </svg>
  </SvgIcon>,
  "TikTok"
);

const ThreadsIcon = createSvgIcon(
  <SvgIcon>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M19.25 8.505c-1.577-5.867-7-5.5-7-5.5s-7.5-.5-7.5 8.995s7.5 8.996 7.5 8.996s4.458.296 6.5-3.918c.667-1.858.5-5.573-6-5.573c0 0-3 0-3 2.5c0 .976 1 2 2.5 2s3.171-1.027 3.5-3c1-6-4.5-6.5-6-4"
        color="currentColor"
      />
    </svg>
  </SvgIcon>,
  "Threads"
);

const DiscordIcon = createSvgIcon(
  <SvgIcon>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M19.27 5.33C17.94 4.71 16.5 4.26 15 4a.09.09 0 0 0-.07.03c-.18.33-.39.76-.53 1.09a16.09 16.09 0 0 0-4.8 0c-.14-.34-.35-.76-.54-1.09c-.01-.02-.04-.03-.07-.03c-1.5.26-2.93.71-4.27 1.33c-.01 0-.02.01-.03.02c-2.72 4.07-3.47 8.03-3.1 11.95c0 .02.01.04.03.05c1.8 1.32 3.53 2.12 5.24 2.65c.03.01.06 0 .07-.02c.4-.55.76-1.13 1.07-1.74c.02-.04 0-.08-.04-.09c-.57-.22-1.11-.48-1.64-.78c-.04-.02-.04-.08-.01-.11c.11-.08.22-.17.33-.25c.02-.02.05-.02.07-.01c3.44 1.57 7.15 1.57 10.55 0c.02-.01.05-.01.07.01c.11.09.22.17.33.26c.04.03.04.09-.01.11c-.52.31-1.07.56-1.64.78c-.04.01-.05.06-.04.09c.32.61.68 1.19 1.07 1.74c.03.01.06.02.09.01c1.72-.53 3.45-1.33 5.25-2.65c.02-.01.03-.03.03-.05c.44-4.53-.73-8.46-3.1-11.95c-.01-.01-.02-.02-.04-.02M8.52 14.91c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.84 2.12-1.89 2.12m6.97 0c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.83 2.12-1.89 2.12"
      />
    </svg>
  </SvgIcon>,
  "Discord"
);

const Card = () => {
  return (
    <div>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 0,
        }}
      >
        <WaterWave
          perturbance={0.02}
          resolution={512}
          imageUrl={image as string}
          style={{
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          {({ pause, play }) => (

          <Grid container spacing={0}          
          alignItems="center" style={{
            minWidth: "100%",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"}}>
          <Container maxWidth="xl" sx={{bgcolor: '#050f66e6', color: "white", display:'flex', justifyContent:'center'}} className="container">
            <Typography variant="h1" component="h2">
              Minumi Glaucus
            </Typography>
            <img src={sip} alt="Sip" style={{height: "50%", width: "75%", maxWidth: "300px"}} />
            <p style={{color: "white"}}>
              Hello ~ ! My name is Minumi Glaucus I'm a type of Sea Slug also
              known as Glaucus Atlanticus, nice to meet you! 🫧🏝️
            </p>
            <p style={{color: "white"}}>I love gaming (I love rhythm games), drawing and singing!</p>

            <div style={{width: "100%", height: "100%"}}>
              <IconButton 
                size="large"
                href="https://www.twitch.tv/minumiglaucus"
                target="_blank"
                style={{color: "#6441a5"}}
                aria-label="Twitch"
              >
                <TwitchIcon style={{width: 100, height: 100, maxWidth:"10vw", maxHeight: "10vw"}} fontSize="inherit"/>
              </IconButton>
              <IconButton
                href="https://twitter.com/minumiglaucus"
                target="_blank"
                style={{color: "#1DA1F2"}}
                aria-label="Twitter/X"
              >
                <TwitterIcon style={{width: 100, height: 100, maxWidth:"10vw", maxHeight: "10vw"}}/>
              </IconButton>
              <IconButton
                href="https://www.youtube.com/channel/UCBBsy-JxA0ODiZUHJ6-YsnQ"
                target="_blank"
                style={{color: "#FF0000"}}
                aria-label="Youtube"
              >
                <YouTubeIcon style={{width: 100, height: 100, maxWidth:"10vw", maxHeight: "10vw"}}/>
              </IconButton>

              <IconButton
                href="https://discord.com/invite/drhHYda2Vv"
                target="_blank"
                style={{color: "#7289da"}}
                aria-label="Discord"
              >
                <DiscordIcon style={{width: 100, height: 100, maxWidth:"10vw", maxHeight: "10vw"}}/>
              </IconButton>
              <IconButton
                href="https://www.tiktok.com/@minumiglaucus"
                target="_blank"
                style={{color: "#000000"}}
                aria-label="Tiktok">
                <TikTokIcon style={{width: 100, height: 100, maxWidth:"10vw", maxHeight: "10vw"}}/>
              </IconButton>
              <IconButton
                href="https://www.instagram.com/minumi.glaucus/"
                target="_blank"
                className="gradient"
                aria-label="Instagram"
              >
                <InstagramIcon style={{width: 100, height: 100, maxWidth:"10vw", maxHeight: "10vw"}}/>
              </IconButton>
              <IconButton
                href="https://www.threads.net/@minumi.glaucus"
                target="_blank"
                color="secondary"
                style={{color: "#6441a5"}}
                aria-label="Threads">
                <ThreadsIcon style={{width: 100, height: 100, maxWidth:"10vw", maxHeight: "10vw"}}/>
              </IconButton>
            </div>
          </Container>
          </Grid>
          )}
        </WaterWave>
    </div>

</div>
  );
};

export default Card;
